@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0rem;
  padding: 0rem;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1700px;
  margin: auto;
  background-color: rgba(240, 248, 255, 0.332);

}
img {
  width: 100%;
  height: 100%;
}
.item-center{
  align-items: center;
}
/* signup/login background */
.Signup {
  background: -o-linear-gradient(to right, #78ffd6, #007991);
  background: -webkit-linear-gradient(to right, #78ffd6, #007991);
  background: linear-gradient(rgb(120, 255, 214), rgb(0, 121, 145));
  padding-top: 11.7rem;
  padding-bottom: 30vh;

}
.productName{
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.productinfoFont {
  font-family: monospace;
}
/* for menu */
@media (max-height: 645px) {
  .Menu {
    height: 90vh;
    overflow-y: auto;
    padding-bottom: 0.9rem;
  }
}
/* gender selection component in cloth and shoe */
@media (max-width: 452px) {
  .genderSelection{
    height: 2.5rem;
  }
}

/* for page loading animation */
.loader > div {
  display: inline-block;
  animation: Bounce 1.5s infinite both;
}
@keyframes Bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
 .loader .loader1 {
  animation-delay: -0.3s;
}
  .loader .loader2 {
  animation-delay: -0.15s;
}
/* for card loading animation*/
.card .img{
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background: linear-gradient(110deg, #ececec 8%, #fcfafa 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
/* checkout styling to display error in an input field */
.red{
  background: rgba(247, 231, 231, 0.516);
  
}
